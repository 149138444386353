.projects-container {
  height: 100%;
  width: auto;
  position: relative;
}

.projects-greeting {
  padding: 1rem;
}

.projects {
  width: 50%;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  position: relative;
}

.project {
  height: 100%;
  background-color: var(--color-bg-variant);
  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid transparent;
  transition: var(--transition);
  overflow: hidden;
  position: relative;
}

.project:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
}

.project:before {
  content: '';
  background: var(--color-light);
  width: 50%;
  height: 100%;
  top: 0%;
  transform: skew(30deg);
  transition: left 400ms ease-out;
  left: -200%;
  position: absolute;
}

.project:hover:before {
  left: 200%;
}

.project:hover h4 {
  color: var(--color-primary);
}

.project:hover small {
  color: var(--color-white)
}

.project h4 {
  margin-bottom: 1rem;
  color: var(--color-white)
}

.project small {
  font-size: 0.7rem;
  color: var(--color-light);
}

/* ====== MEDIUM DEVICES ====== */

@media screen 
  and (min-width: 641px)
  and (max-width: 1079px) {
    .projects-container {
      width: 100%;
      height: 100%;
    }

    .projects {
      width: 50%;
      grid-template-columns: 1fr;
      gap: 1 rem;
    }
}

/* ====== SMALL DEVICES ====== */

@media screen
  and (max-width: 640px) {
    .projects-container {
      width: 100%;
      height: 100%;
      justify-content: flex-start;
    }

    .projects {
      width: 100%;
      grid-template-columns: 1fr;
    }
}
.contact-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.contact-greeting {
  padding: 1rem;
}

.my-contact-info {
  padding: 1rem;
  width: 33%;
  justify-content: center;
  align-items: center;
  position: relative;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
}

input, textarea {
  font-family: 'Poppins', sans-serif;
  width: 100%;
  padding: 1rem;
  border-radius: 1.5rem;
  font-size: 1rem;
  background: transparent;
  border: 1px solid var(--color-primary-variant);
  resize: none;
  color: var(--color-white);
}

button {
  font-size: 1rem;
  width: 25%;
}

.contact-socials {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.social-icon {
  color: var(--color-light);
}

.social-icon:hover {
  color: var(--color-primary);
}

/* ====== SMALL DEVICES ====== */

@media screen
  and (max-width: 640px) {
    .contact-container {
      width: 100%;
      justify-content: space-between;
    }

    .my-contact-info {
      width: 100%;
      grid-template-columns: 1fr;
      gap: 1rem;
    }

    .contact-socials {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      height: auto;
    }

    .social-icon {
      font-size: 1rem;
      color: var(--color-primary)
    }
}

/* ====== MEDIUM DEVICES ====== */

@media screen
  and (min-width: 641px)
  and (max-width: 1079px) {
    .contact-container {
      width: 100%;
    }

    .my-contact-info {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      grid-template-columns: 1fr;
      gap: 1rem;
    }

    .contact-email, .contact-socials{
      width: 50%;
    }

    .contact-socials {
      display: flex;
      justify-content: space-around;
    }

    .social-icon {
      color: var(--color-primary)
    }
}
@import url('https://fonts.googleapis.com/css2?family=Major+Mono+Display&family=Poppins:wght@300&display=swap');

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-bg: linear-gradient(#242637, #30313e);
  --color-bg-variant: #3c3c53;
  --color-primary: #4db5ff;
  --color-primary-variant: rgba(59, 94, 119, 0.4);
  --color-white: #ffffff;
  --color-light: rgba(255, 255, 255, 0.5);

  --transition: all 400ms ease;

  --container-width-lg: 95%;
  --container-width-md: 95%;
  --container-width-sm: 90%;
}

::-webkit-scrollbar {
  display: none;
}

html, body, button {
  font-family: 'Major Mono Display', monospace;
  background: var(--color-bg) fixed;
  color: white;
  line-height: 1.7;
  text-align: center;
}

.main {
  /* border: 1px solid yellow; */
  width: 100%;
  padding-top: 75.19px;
  height: calc(100% - 7.19px);
  position: absolute;
}

.container {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: pageLoad 400ms ease-in;
}

h1 {
  color: var(--color-primary);
}

h2, h5 {
  color: var(--color-light);
}

.btn {
  color: var(--color-primary);
  padding: 1rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  position: relative;
}

.btn:hover {
  transform: translateY(-15%);
}

.btn:before {
  content: '';
  background: var(--color-light);
  width: 50%;
  height: 100%;
  top: 0%;
  transform: skew(45deg);
  transition: left 400ms ease-out;
  left: -125%;
  position: absolute;
}

.btn:hover:before {
  left:150%;
}

.btn-primary {
  border: transparent;
  background: var(--color-primary);
  color: var(--color-bg-variant);
}

@keyframes pageLoad {
  from {
    opacity: 0;
    transform: translateY(25px);
  }
  
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* ====== MEDIUM DEVICES ====== */

@media only screen
  and (max-width: 1007px) {
    .main {
      width: 100%;
      height: 100%;
    }
}

/* ====== SMALL DEVICES ====== */

@media only screen
  and (max-width: 640px) {
    .main {
      width: 100%;
      height: 100%;
    }

    .container {
      width: var(--container-width-sm);
    }
}

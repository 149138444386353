.exp-container {
  height: 100%;
  width: auto;
  position: relative;
}

.exp-greeting {
  padding: 1rem;
}

.exp {
  width: auto;
  position: relative;
  padding: 1rem;
}

.skills {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  position: relative;
}

.skills > div {
  background: var(--color-bg-variant);
  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

/* .skills > div:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
} */

.skills > div h3 {
  margin-bottom: 2rem;
  color: var(--color-primary)
}

.skills-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 2rem;
}

.skill {
  display: flex;
  gap: 1rem;
}

.skill-icon {
  color: var(--color-primary);
  font-size: 1.5rem;
}

/* ====== SMALL DEVICES ====== */

@media screen 
  and (max-width: 640px) {
    .exp-container {
      width: 100%;
      height: 100%;
      justify-content: flex-start;
    }

    .exp {
      width: 100%;
    }

    
    .skills {
      grid-template-columns: 1fr;
      min-width: 100%;
      gap: 1rem;
    }
    
    .skills-content {
      grid-template-columns: 1fr;
      gap: 1rem;
    }
    
    .skill {
      justify-content: center;
      font-size: 0.9rem;
    }
  }
  
/* ====== MEDIUM DEVICES ====== */

@media screen
  and (max-width: 1079px)
  and (min-width: 641px) {
    .exp-container {
      width: 100%;
      height: 100%;
    }
    
    .exp {
      width: 50%;
    }

    .skills {
      grid-template-columns: 1fr;
      gap: 1rem;
    }

    .skills-content {
      grid-template-columns: 1fr;
      gap: 1rem;
    }

    .skill {
      justify-content: center;
    }
}
.home-container {
  /* border: 1px solid blue; */
  height: 100%;
  justify-content: center;
  align-items: center;
}

.home-welcome {
  width: auto;
  height: auto;
  padding: 1rem;
  position: relative;
}

img {
  border-radius: 50% 50%;
  width: 200px;
  opacity: 0.9;
}

.action {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
}

/* ====== MEDIUM DEVICES ====== */

@media only screen 
  and (max-width: 1079px)
  and (min-width: 641px) {
    .home-container {
      width: 100%;
      height: 100%;
    }
}

/* ====== SMALL DEVICES ====== */

@media only screen 
  and (max-width: 640px) {
    .home-container {
      width: 100%;
      height: 100%;
    }
}
.about-container {
  height: 100%;
  width: auto;
  position: relative;
}

.about-greeting {
  padding: 1rem;
}

.about-me {
  width: 50%;
  position: relative;
  padding: 1rem;
}

.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  position: relative;
}

.card {
  background: var(--color-bg-variant);
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 1rem;
  transition: var(--transition);
}

/* .card:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
} */

.card-icon {
  color: var(--color-primary);
  font-size: 2rem;
  margin-bottom: 1rem;
}

.card h5 {
  font-size: 0.75rem;
  color: white;
}

.card small {
  font-size: 0.7rem;
  color: var(--color-light)
}

.about-me p {
  padding-top: 1rem;
  font-size: 0.9rem;
}

/* ====== MEDIUM DEVICES ====== */

@media screen 
  and (max-width: 1079px) 
  and (min-width: 641px) {
    .about-container {
      width: 100%;
      height: 100%;
    }

    .cards {
      grid-template-columns: 1fr;
      gap: 1rem;
    }
}

/* ====== SMALL DEVICES ====== */

@media screen
  and (max-width: 640px) {
    .about-container {
      width: 100%;
      height: auto;
    }

    .cards {
      grid-template-columns: 1fr;
      gap: 1rem;
    }

    .about-me {
      width: 100%;
    } 
}